import React from "react"
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import HalfHero from '../components/half-hero'
import EuNavbar from '../components/eu-navbar'

export const AboutPageTemplate = ({ title, bodyContent }) => {

  return (
    <>
      <SEO title="About Us" />

      <HalfHero fullWidth />

      <section style={{marginTop: '2rem'}}>

        <h1>{title}</h1>

        <div className="about-us">
          <div className="about-us__content" dangerouslySetInnerHTML={{ __html: bodyContent }} />
          <div className="about-us__map">
            <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2782.661256476717!2d15.972457015520414!3d45.777978579105955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4765d678550a6d87%3A0x19d4422c69d308a7!2sTehnolo%C5%A1ki+park+Zagreb+-+Novi+Zagreb!5e0!3m2!1shr!2shr!4v1530254653140" className="google-map-frame" frameBorder="0" allowFullScreen></iframe>
          </div>

        </div>

        <EuNavbar />

      </section>

    </>
  )
}

const AboutPage = ({ data }) => {
  const { about } = data

  return (
    <AboutPageTemplate
      title={about.frontmatter.title}
      bodyContent={about.html}
    />
  )
}

export default AboutPage


export const aboutPageQuery = graphql`
  query AboutUs {
    about: markdownRemark( fields: { parent: { relativeDirectory: { eq: ""}, name: {eq: "about-us"}}}) { 
      frontmatter {
        title
      }
      html
    }
  }
`