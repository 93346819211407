import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

const EuNavbar = () => {
  const images = useStaticQuery(graphql`
    query AboutUsImages{
      image1: file( name: { eq: "logo_eu"}) {
        childImageSharp {
          fixed(height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image2: file( name: { eq: "logo_fondovi"}) {
        childImageSharp {
          fixed(height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image3: file( name: { eq: "logo_operativnog_programa"}) {
        childImageSharp {
          fixed(height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const { image1, image2, image3 } = images

  return (
    <div className="eu-navbar">
      <div className="eu-navbar__images">
        <Img fixed={image1.childImageSharp.fixed} />
        <Img fixed={image2.childImageSharp.fixed} />
        <Img fixed={image3.childImageSharp.fixed} />
      </div>
      <div className="eu-navbar__text">
        <span>The project is co-financed by the European Union from the European Regional Development Fund.</span>
      </div>
    </div>
  )

}

export default EuNavbar